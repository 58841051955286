import { ServiceRequest } from "src/utils/service-request"
import { CETIP_EMISSAO_PATH, DATALAKE_CONTRAPARTE_PATH, DATALAKE_EMISSAO_CARACTERISTICAS_PATH, DATALAKE_EMISSAO_LTV_PATH, DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH, DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH, DATALAKE_FINCS_MARKET_DATA_PATH, DATALAKE_RATING_DEVEDOR_PATH, DATALAKE_RATING_EMISSAO_PATH, DATALAKE_SPREADS_NEGOCIADOS, ULTIMO_EVENTO_CREDITO_DEVEDOR_PATH, ULTIMO_EVENTO_CREDITO_EMISSAO_PATH, ULTIMO_RATING_DEVEDOR_PATH, ULTIMO_RATING_EMISSAO_PATH, ULTIMO_SPREADS_NEGOCIADOS_PATH } from "src/utils/api-routes"
import { removeValoresVaziosPayloadTrocaPorNull } from "src/utils/object"
import { formatSlashDate } from "src/utils/format-date"
import { getTextValue } from "src/utils/strings"
import { formatDecimalCaseWithComma, getFormattedNumberValue, removerPontosVirgulaValor } from "src/utils/format-decimal"

class EmissaoService {
    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    async listCetipIsinEmissao(cetipIsin) {
        return this.serviceRequest.get(`${CETIP_EMISSAO_PATH}?cetip_isin=${cetipIsin.toUpperCase()}`)

    }

    async listCetipIsinFincs(cetipIsin) {
        return this.serviceRequest.get(`${DATALAKE_FINCS_MARKET_DATA_PATH}/cetip-isin/${cetipIsin.toUpperCase()}`)
    }

    async getUltimoRatingEmissao(cetipIsin) {
        return this.serviceRequest.get(`${ULTIMO_RATING_EMISSAO_PATH}?cetip=${cetipIsin.toUpperCase()}`)
    }

    async getUltimoRatingDevedor(cpfCnpj) {
        return this.serviceRequest.get(`${ULTIMO_RATING_DEVEDOR_PATH}?cpf_cnpj=${cpfCnpj}`)
    }

    async getUltimoEventoCreditoEmissao(cetipIsin) {
        return this.serviceRequest.get(`${ULTIMO_EVENTO_CREDITO_EMISSAO_PATH}?cetip=${cetipIsin.toUpperCase()}`)
    }

    async getUltimoEventoCreditoDevedor(cpfCnpj) {
        return this.serviceRequest.get(`${ULTIMO_EVENTO_CREDITO_DEVEDOR_PATH}?cpf_cnpj=${cpfCnpj}`)
    }

    async getUltimoSpreadNegociados(cetip) {
        return this.serviceRequest.get(`${ULTIMO_SPREADS_NEGOCIADOS_PATH}?cetip=${cetip.toUpperCase()}`)
    }

    async ratingEmissao(method, value, body = null) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_RATING_EMISSAO_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_RATING_EMISSAO_PATH}`, value)
            case "patch":
                return this.serviceRequest.patch(`${DATALAKE_RATING_EMISSAO_PATH}/${value}`, body)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_RATING_EMISSAO_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    async ratingDevedor(method, value, body = null) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_RATING_DEVEDOR_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_RATING_DEVEDOR_PATH}`, value)
            case "patch":
                return this.serviceRequest.patch(`${DATALAKE_RATING_DEVEDOR_PATH}/${value}`, body)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_RATING_DEVEDOR_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }
    async eventoCreditoEmissao(method, value, body = null) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}`, value)
            case "patch":
                return this.serviceRequest.patch(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}/${value}`, body)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    async eventoCreditoDevedor(method, value, body = null) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}`, value)
            case "patch":
                return this.serviceRequest.patch(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}/${value}`, body)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    async ultimoNegocios(method, value) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_SPREADS_NEGOCIADOS}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_SPREADS_NEGOCIADOS}`, value)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_SPREADS_NEGOCIADOS}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    // Função para limpar valores string
    sanitizeValue(value, defaultValue = null) {
        return value?.trim() || defaultValue
    }

    // Função para formatar valores numéricos
    formatValue(value, defaultValue = 0) {
        return removerPontosVirgulaValor(value) || defaultValue
    }

    async registerCaracteristicasEmissao(value = {}) {
        const body = {
            CETIP: this.sanitizeValue(value.CETIP),
            VersaoDocumento: this.sanitizeValue(value.VersaoDocumento),
            DataDocumentacao: value.DataDocumentacao
                ? formatSlashDate(value.DataDocumentacao).trim()
                : "",
            TipoAmortizacao: this.sanitizeValue(value.TipoAmortizacao),
            CotaSubordinacao: this.sanitizeValue(value.CotaSubordinacao),
            ValorSerie: removerPontosVirgulaValor(value.ValorSerie) || 0,
            ValorTotalEmissao: removerPontosVirgulaValor(value.ValorTotalEmissao) || 0,
            AgenteFiduciario: this.sanitizeValue(value.AgenteFiduciario),
            CoordenadorLider: this.sanitizeValue(value.CoordenadorLider),
            IFContaRecebedora: this.sanitizeValue(value.IFContaRecebedora),
            Securitizador: this.sanitizeValue(value.Securitizador),
            Custodiante: this.sanitizeValue(value.Custodiante),
            PrePagamento: this.sanitizeValue(value.PrePagamento),
            Cedente: this.sanitizeValue(value.Cedente),
            Auditoria: this.sanitizeValue(value.Auditoria),
            AgenciaRating: this.sanitizeValue(value.AgenciaRating),
            Devedor: this.sanitizeValue(value.Devedor),
            CNPJ_CPF_Devedor: this.sanitizeValue(value.CNPJ_CPF_Devedor),
            ResumoDevedor: this.sanitizeValue(value.ResumoDevedor),
            TipoLastro: this.sanitizeValue(value.TipoLastro),
            Coobrigacao: this.sanitizeValue(value.Coobrigacao),
            ContaVinculada: this.sanitizeValue(value.ContaVinculada),
            Covenants: this.sanitizeValue(value.Covenants),
            RepactuacaoCredito: this.sanitizeValue(value.RepactuacaoCredito),
            AlienacaoFiduciaria: this.sanitizeValue(
                getTextValue(value.AlienacaoFiduciaria),
                "Não tem"
            ),
            CessaoFiduciaria: this.sanitizeValue(getTextValue(value.CessaoFiduciaria), "Não tem"),
            FiancaBancaria: this.sanitizeValue(getTextValue(value.FiancaBancaria), "Não tem"),
            Hipoteca: this.sanitizeValue(getTextValue(value.Hipoteca), "Não tem"),
            Penhor: this.sanitizeValue(getTextValue(value.Penhor), "Não tem"),
            Avalista: this.sanitizeValue(getTextValue(value.Avalista), "Não tem"),
            Fianca: this.sanitizeValue(getTextValue(value.Fianca), "Não tem"),
            FundoObra: this.sanitizeValue(getTextValue(value.FundoObra), "Não tem"),
            FundoReserva: this.sanitizeValue(getTextValue(value.FundoReserva), "Não tem"),
            FundoLiquidez: this.sanitizeValue(getTextValue(value.FundoLiquidez), "Não tem"),
            Seguro: this.sanitizeValue(getTextValue(value.Seguro), "Não tem"),
            Sobregarantia: this.sanitizeValue(getTextValue(value.Sobregarantia), "Não tem"),
        }

        return this.serviceRequest.post(DATALAKE_EMISSAO_CARACTERISTICAS_PATH, body)
    }

    async registerLTVEmissao(value = {}) {
        const body = {
            CETIP: this.sanitizeValue(value.CETIP),
            DataReferencia: value.DataDocumentacao
                ? formatSlashDate(value.DataDocumentacao).trim()
                : "",
            IndiceSubordinacao: this.formatValue(value.IndiceSubordinacao),
            LTV: this.formatValue(value.LTV),
            AlienacaoFiduciaria: getFormattedNumberValue(value.AlienacaoFiduciaria) || 0,
            CessaoFiduciaria: getFormattedNumberValue(value.AlienacaoFiduciaria) || 0,
            FiancaBancaria: getFormattedNumberValue(value.AlienacaoFiduciaria) || 0,
            Hipoteca: getFormattedNumberValue(value.Hipoteca) || 0,
            Penhor: getFormattedNumberValue(value.Penhor) || 0,
            Avalista: getFormattedNumberValue(value.Avalista) || 0,
            Fianca: getFormattedNumberValue(value.Fianca) || 0,
            FundoObra: getFormattedNumberValue(value.FundoObra) || 0,
            FundoReserva: getFormattedNumberValue(value.FundoReserva) || 0,
            FundoLiquidez: getFormattedNumberValue(value.FundoLiquidez) || 0,
            Seguro: getFormattedNumberValue(value.Seguro) || 0,
            Sobregarantia: getFormattedNumberValue(value.Sobregarantia) || 0,
        }

        return this.serviceRequest.post(DATALAKE_EMISSAO_LTV_PATH, body)
    }

    async removeCaracteristicas(id) {
        return this.serviceRequest.delete(`${DATALAKE_EMISSAO_CARACTERISTICAS_PATH}/${id}`)
    }

    async verificaExisteCetipVersaoDocumento(CETIP, VersaoDocumento) {
        return this.serviceRequest.get(`${DATALAKE_EMISSAO_CARACTERISTICAS_PATH}?CETIP=${CETIP}&VersaoDocumento=${VersaoDocumento}`)
    }

    async verificaExisteCetipDataReferencia(CETIP, DataReferencia) {
        DataReferencia = formatSlashDate(DataReferencia)
        return this.serviceRequest.get(`${DATALAKE_EMISSAO_LTV_PATH}?CETIP=${CETIP}&DataReferencia=${DataReferencia}`)
    }

    async recuperaDadosContraparte(input, value) {
        return this.serviceRequest.get(`${DATALAKE_CONTRAPARTE_PATH}?${input}=${value}`)
    }

    calculaLTV(body) {
        const preparaCampos = {
            ValorSerie: removerPontosVirgulaValor(body.ValorSerie) || 0,
            ValorTotalEmissao: removerPontosVirgulaValor(body.ValorTotalEmissao) || 0,
            IndiceSubordinacao: removerPontosVirgulaValor(body.IndiceSubordinacao) || 0,
            AlienacaoFiduciaria: getFormattedNumberValue(body.AlienacaoFiduciaria) || 0,
            CessaoFiduciaria: getFormattedNumberValue(body.CessaoFiduciaria) || 0,
            FiancaBancaria: getFormattedNumberValue(body.FiancaBancaria) || 0,
            Hipoteca: getFormattedNumberValue(body.Hipoteca) || 0,
            Penhor: getFormattedNumberValue(body.Penhor) || 0,
            Avalista: getFormattedNumberValue(body.Avalista) || 0,
            Fianca: getFormattedNumberValue(body.Fianca) || 0,
            FundoObra: getFormattedNumberValue(body.FundoObra) || 0,
            FundoReserva: getFormattedNumberValue(body.FundoReserva) || 0,
            FundoLiquidez: getFormattedNumberValue(body.FundoLiquidez) || 0,
            Seguro: getFormattedNumberValue(body.Seguro) || 0,
            Sobregarantia: getFormattedNumberValue(body.Sobregarantia) || 0,
        }

        const {
            ValorSerie = 0,
            ValorTotalEmissao = 0,
            IndiceSubordinacao = 0,
            AlienacaoFiduciaria = 0,
            CessaoFiduciaria = 0,
            FiancaBancaria = 0,
            Hipoteca = 0,
            Penhor = 0,
            Avalista = 0,
            Fianca = 0,
            FundoObra = 0,
            FundoReserva = 0,
            FundoLiquidez = 0,
            Seguro = 0,
            Sobregarantia = 0,
        } = preparaCampos

        const sum =
            AlienacaoFiduciaria +
            CessaoFiduciaria +
            FiancaBancaria +
            Hipoteca +
            Penhor +
            Avalista +
            Fianca +
            FundoObra +
            FundoReserva +
            FundoLiquidez +
            Seguro +
            Sobregarantia

        // Prevenir divisão por zero
        const denominator = (ValorTotalEmissao * IndiceSubordinacao) + sum

        if (denominator === 0) {
            return
        }

        const somaFinal = (ValorSerie / denominator) * 100
        return formatDecimalCaseWithComma(somaFinal, 4)
    }

}

export default EmissaoService
import { Box, Grid, Tab, Tabs , Button, Autocomplete,TextField} from "@mui/material"
import React, { useState,useEffect } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import AlertMessage from "src/components/alert-message"
import Balanços from "./demo_financeiras"
import EmpresaPage from "./empresa_page"
import OutroDocumentos from "./outros_documentos"
import GrupoEconomico from "./grupo_economico"
import Emissoes from "./emissoes"
import DatalakeService from "src/services/datalake-service"
import { DATALAKE_CONTRAPARTE_PATH  } from "src/utils/api-routes"
import ModalDialogPage from "src/components/modal-dialog"
import { DataGrid } from "@mui/x-data-grid"


const EmpresasPage = () => {
    const [data, setData] = useState([])
    const calcService = new DatalakeService()
    const [selectedTab, setSelectedTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const [isLoading, setIsLoading] = useState(false)
    const [openModalError, setOpenModalError] = useState(false)
    const [modalCompleteList, setModalCompleteList] = useState(false)
    const [reportErrorMessage, setReportErrorMessage] = useState("")
    const [EmpresasList, setEmpresasList] = useState([])
    const endpoint= DATALAKE_CONTRAPARTE_PATH

    const sendReportError = () => {
        console.log('enviando') 
        setLoading(true)
        calcService.postReportError(reportErrorMessage)
            .then(() => {
                setOpenModalError(false)
                openAlert("Mensagem enviada com sucesso", "success")
            })
            .catch(() => {
                openAlert("Erro ao enviar mensagem, tente novamente", "error")
            }).finally(() => {
                setLoading(false)
            })
    }
    
    const loadData = async () => {
        setIsLoading(true)
        calcService.loadEndpoint(endpoint).then((response) => {
            setIsLoading(false)
            const { body } = response
            const { results } = body


            const list = results
                .filter((obj) => obj.Setor !== "Consórcio" && obj.TipoCadastro !== "Filial")
                .map((obj, index) => {
                    const formattedCPFCNPJ = obj.CPFCNPJ?.replace(/[^\d]/g, '') 
                    const displayString = `${obj.RazaoSocial} - ${obj.CPFCNPJ}` 

                    return {
                        ...obj,
                        id: index,
                        formattedCPFCNPJ,
                        displayString
                    }
            })
            setEmpresasList(list)
            setData(list)
        })
    }

    useEffect(() => {
        loadData()
    }, []) 
    
    const removeDiacritics = (str) => 
        str.normalize("NFD").replace(/[\u0300-\u036f]/g, '')

    const filterAutocompleteOptions = (options, inputValue) => {
        // Remover acentos e caracteres especiais
        const sanitizedInput = removeDiacritics(inputValue.toLowerCase().trim()).replace(/[^\w\s]/g, '')
        
        return options.filter((option) => {
            // Extrair a parte do CNPJ antes da barra
            const formattedCNPJ = option.formattedCPFCNPJ.split('/')[0].replace(/[^\d]/g, '')
    
            // Construir o displayString
            const displayString = `${option.RazaoSocial} - ${option.formattedCPFCNPJ}`
    
            // Comparar o input com o displayString (com e sem caracteres especiais) e o CNPJ
            return (
                removeDiacritics(displayString.toLowerCase()).includes(sanitizedInput) ||
                formattedCNPJ.includes(sanitizedInput)
            )
        })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const handleClickMostrarTodosTable = (data) => {
        const { row } = data
        
        setSelectedOption({
            ...row,
            displayString: `${row.RazaoSocial} - ${row.CPFCNPJ}`,
        })
    
        setModalCompleteList(false)
    }

    const renderContent = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <>
                        < EmpresaPage loading={setLoading} alert={openAlert} empresa={selectedOption}/>
                    </>
                )
            case 1:
                return (
                    <>
                        < Balanços loading={setLoading} alert={openAlert} />
                    </>
                )
            case 2:
                return (
                    <>
                        < Emissoes loading={setLoading} alert={openAlert} empresa={selectedOption}/>
                    </>
                )
            case 3:
                return (
                    <>
                        < GrupoEconomico loading={setLoading} alert={openAlert} empresa={selectedOption}/>
                    </>
                )
            case 4:
                return (
                    <>
                        < OutroDocumentos loading={setLoading} alert={openAlert} />
                    </>
                )
            default:
                return null
        }
    }

    return (
        <LayoutPage title="Empresas" loading={loading}>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <Grid direction={"row"} container alignItems="center" justifyContent="space-between" spacing={3}>
                <Grid item xs={6} md={8} sm={12}>
                    <Grid container direction={"row"} spacing={3} alignItems="center">
                        <Grid  item xs={4} md={6} sm={6}>
                            <Autocomplete
                                options={data}
                                getOptionLabel={(option) => option.displayString || ''} 
                                filterOptions={(options, { inputValue }) =>
                                    filterAutocompleteOptions(options, inputValue)
                                }
                                value={selectedOption}
                                onChange={(event, newValue) => setSelectedOption(newValue)}
                                loading={isLoading}
                                renderInput={(params) => (
                                    <TextField {...params} label="Razão Social ou CNPJ" variant="outlined" />
                                )}
                                fullWidth
                                loadingText="Carregando opções..."
                            />
                        </Grid>
                        <Grid item xs={6} md={6} sm={6}>
                            <Button color={"primary"} variant={"contained"} onClick={() => setModalCompleteList(true)}>Consultar Lista Completa
                            
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button color={"primary"} variant={"contained"} onClick={() => { setReportErrorMessage(null), setOpenModalError(true) }}>Reportar Erro
                    </Button>
                </Grid>
            </Grid>

            <br />
            
            <Grid container direction={"row"} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                    <Box
                        sx={{
                            height: "100vh",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)} aria-label="tabs">
                            <Tab label={"EMPRESA"} key={0} />
                            <Tab label={"BALANÇOS"} key={1} />
                            <Tab label={"EMISSÕES"} key={2} />
                            <Tab label={"GRUPO ECONÔMICO"} key={3} />
                            <Tab label={"OUTROS DOCUMENTOS"} key={4} />
                        </Tabs>

                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <Grid
                                container
                                direction="row"
                                alignContent="flex-start"
                                justifyContent="flex-start"
                                spacing={0}
                                sx={{
                                    padding: 2,
                                    height: "100%", // altura de 100% da Box
                                }}>
                                <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                                    {renderContent()}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <ModalDialogPage open={openModalError}
                title="Reportar Erro"
                close={() => {
                    setOpenModalError(false)
                }}>
                <>
                    <Grid container direction="column">
                        <Grid item xs={12} md={12} xl={12}>
                            <TextField
                                label="Mensagem"
                                variant="outlined"
                                placeholder="Descreva o erro ou inconsistência nas informações que encontrou que nosso time de suporte irá rever!"
                                multiline
                                focused
                                minRows={10}
                                value={reportErrorMessage}
                                onChange={(event) => setReportErrorMessage(event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container direction="column" alignContent={"flex-end"} justifyContent={"flex-end"}>
                        <Grid item>
                            <Button color={"primary"} variant={"contained"} onClick={sendReportError}>Reportar Erro</Button>
                        </Grid>
                    </Grid>
                </>
            </ModalDialogPage>

            <ModalDialogPage open={modalCompleteList}
                title="Lista de Empresas"
                close={() => {
                    setModalCompleteList(false)
                }}>
                <>
                    <Grid container direction="column">
                        <Grid item xs={12} md={12} xl={12}>
                            <DataGrid
                                autoHeight
                                columns={[
                                    { field: 'CPFCNPJ', headerName: 'CPFCNPJ', width: 200 },
                                    { field: 'RaizCNPJ', headerName: 'Raiz CNPJ', width: 200 },
                                    { field: 'RazaoSocial', headerName: 'Razão Social', width: 300 },
                                    { field: 'NomeFantasia', headerName: 'Nome Fantasia', width: 300 },
                                    { field: 'GrupoEconomico_Nome', headerName: 'Grupo Econômico', width: 300 },
                                    { field: 'TipoCadastro', headerName: 'Tipo Cadastro', width: 300 },
                                ]}
                                rows={EmpresasList}
                                onRowClick={handleClickMostrarTodosTable}
                            />
                        </Grid>
                    </Grid>
                </>
            </ModalDialogPage>
        </LayoutPage>
    )
}

export default EmpresasPage
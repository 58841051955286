import { ServiceRequest } from "src/utils/service-request"
import { DATALAKE_EMPRESAS_PATH, DATALAKE_GRUPO_ECONOMICO_PATH, CALC_REPORT_ERROR } from "src/utils/api-routes"
import { TokenService } from "./token-service"


class DatalakeService {

    constructor() {
        this.serviceRequest = new ServiceRequest()
        this.tokenService = new TokenService()

    }

    async paginate(endpoint) {
        return await this.serviceRequest.getEndpoint(endpoint)
    }

    async loadEndpoint(endpoint, filter = null) {
        return await this.serviceRequest.get(`${endpoint}?limit=10000&${filter}`)
    }

    async loadPaginationEndpoint(endpoint, filter = null, limit = 25, offset = 0) {
        return await this.serviceRequest.get(`${endpoint}?limit=${limit}&offset=${offset}&${filter}`)
    }

    async createTableEndpoint(endpoint, body) {
        console.log(endpoint, body)
        return await this.serviceRequest.post(`${endpoint}`, body)
    }

    async updateTableEndpoint(endpoint, id, body) {
        return await this.serviceRequest.patch(`${endpoint}/${id}`, body)
    }

    async deleteTableEndpoint(endpoint, id) {
        return await this.serviceRequest.delete(`${endpoint}/${id}`)
    }

    async loademissoes( body) {
        return await this.serviceRequest.get(`${DATALAKE_EMPRESAS_PATH}?CPFCNPJ=${body}`)
    }

    async loadgrupoecon( body) {
        return await this.serviceRequest.get(`${DATALAKE_GRUPO_ECONOMICO_PATH}?raiz_cnpj=${body}`)
    }


    async postReportError( message) {
        const { user } = this.tokenService.decodeToken()
        const { username, Cliente, NomeCompleto } = user
        const { Nome } = Cliente

        const body = {
            'title': "Reporte de Erro em Empresas Datalake",
            'data': {
                'client': Nome,
                'name': NomeCompleto,
                'username': username,
                'message': message
            }
        }
        console.log("aqui")
        return this.serviceRequest.post(CALC_REPORT_ERROR, body)
    }

}

export default DatalakeService
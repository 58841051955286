import { INTEGRATION_B3AtivosReg_PATH } from "src/utils/api-routes"
import { INTEGRATION_B3NegAnalitico_PATH } from "src/utils/api-routes"
import { INTEGRATION_B3NegConsolidado_PATH } from "src/utils/api-routes"
import { INTEGRATION_BACEN_CADASTRO_PATH } from "src/utils/api-routes"
import { INTEGRATION_BACEN_CADASTRO_WORKFLOW_PATH } from "src/utils/api-routes"
import { INTEGRATION_BACEN_TAXAS_JUROS_PATH } from "src/utils/api-routes"
import { INTEGRATION_COLETADETAXASERISKFREE_WORKFLOW_PATH } from "src/utils/api-routes"
import { INTEGRATION_CVM_BALANCOS_WORKFLOW_PATH } from "src/utils/api-routes"
import { INTEGRATION_CVM_CADASTRO_WORKFLOW_PATH } from "src/utils/api-routes"
import { INTEGRATION_CVMFATOSRELEVANTES_PATH } from "src/utils/api-routes"
import { INTEGRATION_CVMFATOSRELEVANTES_WORKFLOW_PATH } from "src/utils/api-routes"
import { INTEGRATION_EstoqueRegistrado_PATH } from "src/utils/api-routes"
import { INTEGRATION_MARKETDATA_AIRFLOW_PATH } from "src/utils/api-routes"
import { INTEGRATION_MARKETDATA_PATH } from "src/utils/api-routes"
import { INTEGRATION_PREVIA_INFLACAO_ANBIMA_PATH } from "src/utils/api-routes"
import { INTEGRATION_PREVIAINFLACAO_PATH } from "src/utils/api-routes"
import { INTEGRATION_PREVIA_INFLACAO_WORKFLOW_PATH } from "src/utils/api-routes"
import { INTEGRATION_RISKFREE_PATH } from "src/utils/api-routes"
import { INTEGRATION_TAXAS_RECEBIVEIS_MERCADO_PATH } from "src/utils/api-routes"
import { LOTES_DADOS_EXTERNOS_PATH } from "src/utils/api-routes"
import { REPORT_DADOS_WORKFLOW_PATH } from "src/utils/api-routes"





//import {INTEGRATION_RISKFREE_WORKFLOW_PATH} from "src/utils/api-routes"





import { ServiceRequest } from "src/utils/service-request"

export class IntegrationService{

    constructor(){   
        this.request= new ServiceRequest()
    }

    async startarRotinaMarkeData(data_inicial, data_final){
      //return this.request.post(`${ROUTINE_PATH}?data_referencia=${data_referencia}&qtde_dias=${qtde_dias}`,null)
    return this.request.post(INTEGRATION_MARKETDATA_PATH, {"data_inicial": data_inicial, "data_final": data_final } )

    }
    async startarRotinaMarkeDataAirflow(data_inicial, data_final){
      //return this.request.post(`${ROUTINE_PATH}?data_referencia=${data_referencia}&qtde_dias=${qtde_dias}`,null)
    return this.request.post(INTEGRATION_MARKETDATA_AIRFLOW_PATH, {"data_inicial": data_inicial, "data_final": data_final } )

    }

    async startarRotinaPreviaInflacao(indexadores, dataReferencia){
      //return this.request.post(`${ROUTINE_PATH}?data_referencia=${data_referencia}&qtde_dias=${qtde_dias}`,null)
    return this.request.post(INTEGRATION_PREVIAINFLACAO_PATH, {"indexadores": indexadores, "dataReferencia": dataReferencia} )

    }

    async startarRotinaB3AtivosReg(data_referencia){
    return this.request.post(INTEGRATION_B3AtivosReg_PATH, {"data_referencia": data_referencia} )
    }

    async startarRotinaEstoqueRegistrado(data_referencia){
    return this.request.post(INTEGRATION_EstoqueRegistrado_PATH, {"data_referencia": data_referencia} )

    }

    async startarRotinaB3NegAnalitico(data_referencia){
    return this.request.post(INTEGRATION_B3NegAnalitico_PATH, {"data_referencia": data_referencia} )
  
    }

    async startarRotinaB3NegConsolidado(data_referencia){
    return this.request.post(INTEGRATION_B3NegConsolidado_PATH, {"data_referencia": data_referencia} )
      
    }

    async startarCVMFatosRelevantes(data_referencia){
      return this.request.post(INTEGRATION_CVMFATOSRELEVANTES_PATH, {"data_referencia": data_referencia} )
      }
    
    async startarCVMFatosRelevantesWorkflow(data_referencia){
      return this.request.post(INTEGRATION_CVMFATOSRELEVANTES_WORKFLOW_PATH, {"data_referencia": data_referencia} )
      }

    async startarColetaDeTaxasERiskFree(data_referencia){
      return this.request.post(INTEGRATION_RISKFREE_PATH, {"data_referencia": data_referencia} )
      }

    async startarColetaDeTaxasERiskFreeWorkflow(data_referencia){
      return this.request.post(INTEGRATION_COLETADETAXASERISKFREE_WORKFLOW_PATH, {"data_referencia": data_referencia} )
      }

    async startarPreviaInflacaoAnbima(data_referencia){
      return this.request.post(INTEGRATION_PREVIA_INFLACAO_ANBIMA_PATH, {"data_referencia": data_referencia} )
      }

    async startarPreviaInflacaoWorkflow(data_referencia){
      return this.request.post(INTEGRATION_PREVIA_INFLACAO_WORKFLOW_PATH, {"data_referencia": data_referencia} )
      }
   
    async startarBacenTaxasJuros(data_referencia){
        return this.request.post(INTEGRATION_BACEN_TAXAS_JUROS_PATH, {"data_referencia": data_referencia} )
        }
    
    async startarBacenCadastro(data_referencia) {
        return this.request.post(INTEGRATION_BACEN_CADASTRO_PATH, { "data_referencia": data_referencia })
       } 

    async startarBacenCadastroWorkflow(data_referencia) {
        return this.request.post(INTEGRATION_BACEN_CADASTRO_WORKFLOW_PATH, { "data_referencia": data_referencia })
        } 


    async startarTaxasRecebiveisMercado(data_referencia){
        return this.request.post(INTEGRATION_TAXAS_RECEBIVEIS_MERCADO_PATH, {"data_referencia": data_referencia} )
        }

    async startarCvmCadastroWorkflow(){
        return this.request.post(INTEGRATION_CVM_CADASTRO_WORKFLOW_PATH )
        }
    
    async startarCVMBalancosWorkflow(data_referencia){
      return this.request.post(INTEGRATION_CVM_BALANCOS_WORKFLOW_PATH, {"data_referencia": data_referencia} )
      }


  
    async getReportData(rotina) {
          try {
              const url = `${REPORT_DADOS_WORKFLOW_PATH}?rotina=${rotina}`
              const response = await this.request.get(url)
              return response // Retorna a resposta para o `then` no código chamador
          } catch (error) {
              console.error('Erro na requisição getReportDataInfo:', error) // Exibe o erro no console
              throw error // Lança o erro novamente para que o código chamador caia no `catch`
          }
      }
      
      async getReportDataInfo(rotina, data_referencia) {
        try {
            const url = `${REPORT_DADOS_WORKFLOW_PATH}?rotina=${rotina}&data_referencia=${data_referencia}`
            const response = await this.request.get(url)
            return response // Retorna a resposta para o `then` no código chamador
        } catch (error) {
            console.error('Erro na requisição getReportDataInfo:', error) // Exibe o erro no console
            throw error // Lança o erro novamente para que o código chamador caia no `catch`
        }
    }
        

    async getRoutinesWorkflowInfo() {
      return this.request.get(`${LOTES_DADOS_EXTERNOS_PATH}?limit=200`)
    }
  
    verifyTableStatusWorkflow(value) {
      let text = "Não informado"
      let color = "error"
      switch (value) {
        case "SETUP_TASK":
          text = "SETUP_TASK"
          color = "primary"
          break
        case "GENERATING_MESSAGE":
          text = "GENERATING_MESSAGE"
          color = "primary"
          break   
        case "IN_PROGRESS":
          text = "EM PROGRESSO"
          color = "primary"
          break
        case "FILE_PROCESSING":
          text = "ARQUIVO EM PROGRESSO"
          color = "primary"
          break
        case "EXCEEDED_RETRIES":
          text = "ARQUIVO EM PROGRESSO"
          color = "primary"
          break
        case "PROCESS_DONE":
          text = "ARQUIVO EM PROGRESSO"
          color = "success"
          break
        case "ERROR":
          text = "ERRO"
          color = "error"
          break
        case "READY_TO_CALCULATE":
          text = "READY_TO_CALCULATE"
          color = "primary"
          break  
        case "CALCULATING":
          text = "CALCULANDO"
          color = "primary"
          break
        case "CALCULATE_DONE":
          text = "CALCULADO"
          color = "success"
          break
        case "REPORT_IN_PROGRESS":
          text = "REPORT_IN_PROGRESS"
          color = "primary"
          break
        case "REPORT_DONE":
          text = "REPORT_DONE"
          color = "success"
          break
        case "NOTIFICATION_IN_PROGRESS":
          text = "NOTIFICATION_IN_PROGRESS"
          color = "primary"
          break
        case "NOTIFICATION_DONE":
          text = "NOTIFICATION_DONE"
          color = "success"
          break
      }
  
      return {
        text: text,
        color: color
      }
    }
  

}
